@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
.html {
  font-size: 16px;
}
.landing-h1 {
  font-size: 96px; /* 6 times the base font size (96px) */
  font-weight: 1000;
  font-family: 'Jost', sans-serif;
}

.landing-h2 {
  font-size: 76px; /* 4.75 times the base font size (76px) */
  font-weight: 700;
  font-family: 'Jost', sans-serif;
}

.landing-h3 {
  font-size: 62px;
  font-weight: 600;
  font-family: 'Jost', sans-serif;
}

.landing-h4 {
  font-size: 48px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
}

.landing-h5 {
  font-size: 40px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
}

.landing-h6 {
  font-size: 32px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
}

.landing-h7 {
  font-size: 32px;
  font-weight: 600;
  font-family: 'Jost', sans-serif;
}

.landing-h8 {
  font-size: 20px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
}

.landing-h9 {
  font-size: 20px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
}

.landing-p {
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.landing-caption {
  font-size: 14px;
  font-family: 'Lato', sans-serif;
}
.primary-color {
  color: #007bff;
}
.secondary-color {
  color: #173264;
}

.medium-primary {
  color: #e9f1fc;
}
.medium-primary-bg {
  background-color: #e9f1fc;
}
.light-primary-bg {
  background-color: #bde3ff;
}

.light-primary {
}
.dark-gray-color {
  color: #425268;
}

.medium-gray {
}
.light-gray {
  color: #f8fbff;
}
.text-color {
  color: #0f0504;
}
.landing-button {
  border-radius: 520px;
}
